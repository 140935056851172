import BMPRenderer from "./bmp";
import HTMLRenderer from "./html";
import JPGRenderer from "./jpg";
import MSDocRenderer from "./msdoc";
import PDFRenderer from "./pdf";
import PNGRenderer from "./png";
import TIFFRenderer from "./tiff";
import TXTRenderer from "./txt";
import CSVRenderer from "./csv";
import GIFRenderer from "./gif";
export var DocViewerRenderers = [
    BMPRenderer,
    HTMLRenderer,
    JPGRenderer,
    MSDocRenderer,
    PDFRenderer,
    PNGRenderer,
    TIFFRenderer,
    TXTRenderer,
    CSVRenderer,
    GIFRenderer,
];
