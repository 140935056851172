var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var ButtonPrimaryStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n"], ["\n  background-color: ", ";\n  color: ", ";\n"])), function (props) { return props.theme.primary; }, function (props) { return props.theme.textPrimary; });
export var ButtonSecondaryStyle = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n"], ["\n  background-color: ", ";\n  color: ", ";\n"])), function (props) { return props.theme.secondary; }, function (props) { return props.theme.textSecondary; });
export var Button = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 35px;\n  height: 35px;\n  padding: 0;\n  margin: 0 0 0 5px;\n  text-align: center;\n  font-size: 18px;\n  border: 0;\n  outline: none;\n  cursor: pointer;\n  text-decoration: none;\n  border-radius: 35px;\n  opacity: ", ";\n  pointer-events: ", ";\n  box-shadow: 2px 2px 3px #00000033;\n\n  @media (max-width: 768px) {\n    width: 30px;\n    height: 30px;\n    font-size: 15px;\n  }\n"], ["\n  ", "\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 35px;\n  height: 35px;\n  padding: 0;\n  margin: 0 0 0 5px;\n  text-align: center;\n  font-size: 18px;\n  border: 0;\n  outline: none;\n  cursor: pointer;\n  text-decoration: none;\n  border-radius: 35px;\n  opacity: ", ";\n  pointer-events: ", ";\n  box-shadow: 2px 2px 3px #00000033;\n\n  @media (max-width: 768px) {\n    width: 30px;\n    height: 30px;\n    font-size: 15px;\n  }\n"])), ButtonPrimaryStyle, function (props) { return (props.disabled ? 0.4 : 1); }, function (props) { return (props.disabled ? "none" : "all"); });
export var LinkButton = styled.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 0;\n  outline: none;\n  cursor: pointer;\n  text-decoration: none;\n  border-radius: 35px;\n  background-color: ", ";\n  color: ", ";\n  box-shadow: 2px 2px 3px #00000033;\n\n  width: 35px;\n  height: 35px;\n  font-size: 18px;\n  @media (max-width: 768px) {\n    width: 30px;\n    height: 30px;\n    font-size: 15px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 0;\n  outline: none;\n  cursor: pointer;\n  text-decoration: none;\n  border-radius: 35px;\n  background-color: ", ";\n  color: ", ";\n  box-shadow: 2px 2px 3px #00000033;\n\n  width: 35px;\n  height: 35px;\n  font-size: 18px;\n  @media (max-width: 768px) {\n    width: 30px;\n    height: 30px;\n    font-size: 15px;\n  }\n"])), function (props) { return props.theme.primary; }, function (props) { return props.theme.textPrimary; });
export var ButtonPrimary = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var ButtonSecondary = styled(Button)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ButtonSecondaryStyle);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
